import React, { useState, useEffect, useMemo } from 'react';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { getFilters } from 'helpers/filters';
import { getFootballTournamentStagesScoreData } from 'helpers/score';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import { checkAreFinishedEventsExist } from 'helpers/event';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Group',
      field: 'group',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   events: any[];
   ids: { id: string, kind: string, isSchoolIdInTeam?: boolean }[];
   activeGroupId?: string;
   title?: string;
   subTypeTournament: string;
   teams: any[];
   scoring?: string;
   tournamentId?: string;
   tournamentScoring: number[];
   groups?: any[];
   tournament?: any; 
}

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflow: 'hidden'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function Stage(props: Props) {
   const { activeGroupId, ids, events, teams, title, tournamentScoring, subTypeTournament, tournament, groups } = props;

   const [results, setResults] = useState<any>([]);
   const [resultsFiltered, setResultsFiltered] = useState<any>([]);
   const [filters, setFilters] = useState<any>({});

   const getGroupOptions = () => {
      const groupValues = (Lazy(events) as any)
         .map((event: any) => event.groupWithAge)
         .uniq()
         .sort()
         .toArray();

      return groupValues
         .filter((group: any) => typeof group !== 'undefined')
         .map((group: any) => ({ text: group, value: group }));
   };

   const groupOptions = getGroupOptions() || [];
   const options = { group: groupOptions };

   useEffect(() => {
      const initialFilters = getFilters(FILTER_FIELDS, {
         group: groupOptions[0]?.value || ''
      });

      const resultsData = getFootballTournamentStagesScoreData(
         ids,
         events,
         teams,
         tournamentScoring,
         subTypeTournament
      );

      const eventsFiltered = events.filter((event: any) => event.groupWithAge === initialFilters.group);
      const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFiltered);
      const resultsFilteredData = getFootballTournamentStagesScoreData(
         ids,
         eventsFiltered,
         teams,
         tournamentScoring,
         subTypeTournament
      );

      const sortedResults = isFinishedEventsExist
         ? [...(resultsFilteredData || [])].sort((a, b) => (b?.['Points'] ?? 0) - (a?.['Points'] ?? 0))
         : [...(resultsFilteredData || [])].sort((a, b) => (a?.schoolName ?? '').localeCompare(b?.schoolName ?? ''));

      setResults(resultsData);
      setResultsFiltered(sortedResults);
      setFilters(initialFilters);
      console.log('Initial filters:', initialFilters);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const groupNameFromFilter = useMemo(() => {
      if (!filters.group) return undefined;
      const match = filters.group.match(/^[A-Za-z0-9]+\s+(.*)$/);
      const result = match ? match[1] : filters.group;
      console.log('Computed groupNameFromFilter:', result);
      return result;
   }, [filters.group]);

   const customGroup = useMemo(() => {
      if (!tournament?.customGroups) return undefined;
      const found = tournament.customGroups.find((g: any) => g.name === groupNameFromFilter);
      console.log('Lookup customGroup with name:', groupNameFromFilter, 'found:', found);
      return found;
   }, [groupNameFromFilter, tournament]);

   const stageData = useMemo(() => {
      if (!tournament?.tournamentLayout || !customGroup) return undefined;
      const foundStage = tournament.tournamentLayout.find((s: any) => s.id === customGroup.stageId);
      console.log('Lookup stage with stageId:', customGroup?.stageId, 'found:', foundStage);
      return foundStage;
   }, [customGroup, tournament]);

   const showPointsColumn = stageData?.type === 'GROUP';

   const isResultsExist = results.length > 0;
   let columnsData = isResultsExist ? Object.keys(results[0]) : [];
   if (!showPointsColumn) {
      columnsData = columnsData.filter(key => key !== 'Points');
   }
   const columns = columnsData.map((column) => {
      const columnText = column === 'schoolName' ? 'School name' : column;
      return <TableHeadCell key={`table_column_${column}`}>{columnText}</TableHeadCell>;
   });

   const rows = resultsFiltered.map((row: any, index: number) => {
      const rowCells = columnsData.map((column: string) => {
         const tableCellContent = row[column];
         return <TableCell key={column}>{tableCellContent}</TableCell>;
      });
      return (
         <TableRow key={`table_row_${index}`}>
            <TableCell>{index + 1}</TableCell>
            {rowCells}
         </TableRow>
      );
   });

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      if (filterField === 'group') {
         const eventsFilteredNew = events.filter((event: any) => event.groupWithAge === filterValue);
         const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFilteredNew);
         const resultsFilteredNew = getFootballTournamentStagesScoreData(
            ids,
            eventsFilteredNew,
            teams,
            tournamentScoring,
            subTypeTournament
         );
         const sortedFilteredResults = isFinishedEventsExist
            ? [...(resultsFilteredNew || [])].sort((a, b) => (b?.['Points'] ?? 0) - (a?.['Points'] ?? 0))
            : [...(resultsFilteredNew || [])].sort((a, b) => (a?.schoolName ?? '').localeCompare(b?.schoolName ?? ''));
         setResultsFiltered(sortedFilteredResults);
      }

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   return (
      <Box>
         <PageTitle text={title || ''} />

         <Box>
            <Filters
               fields={FILTER_FIELDS}
               filters={filters}
               options={options}
               onFilterChange={onFilterChange}
            />
            <Divider sx={{ my: 3 }} />
         </Box>

         <TableWrapper>
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableHeadCell>#</TableHeadCell>
                        {columns}
                     </TableRow>
                  </TableHead>

                  <TableBody>{rows}</TableBody>
               </Table>
            </TableContainer>
         </TableWrapper>
      </Box>
   );
}
